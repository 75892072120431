/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 03-Mar-2016, 15:26:00
    Author     : Me
*/

.dropdown-wide{
    width: 100%
}

.vertical{
    
    -webkit-transform: rotate(90deg);   
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

.app-modal-window .modal-dialog {
  width: 900px;
}



.show-bounce.ng-hide-add{
    animation: bounceOutDown 0.5s;    
}

.show-bounce.ng-enter{
    animation: bounceOutDown 0.5s;    
}

.show-bounce.ng-hide-remove{
    animation: bounceInDown 1.0s;
}

.show-bounce.ng-leave{
    animation: bounceInDown 1.0s;
}

.show-slide.ng-hide-add{
    animation: fadeOutUp 0.5s;    
}

.show-slide.ng-hide-remove{
    animation: fadeInDown 1.0s;
}

.show-fade.ng-hide-add{
    animation: fadeOut 0.5s;    
}

.show-fade.ng-hide-remove{
    animation: fadeIn 1.0s;
}


.show-slide-side.ng-hide-remove{
    animation: fadeIn 0.5s;    
}

.show-slide-side.ng-enter{
    animation: fadeIn 0.5s;  
}

.repeat-animation.ng-enter.ng-enter-active, 
.repeat-animation.ng-leave {
    opacity: 1;
    -webkit-transition: opacity 300ms linear;
    -moz-transition: opacity 300ms linear;
    transition: opacity 300ms linear;
}

/* Remove animation */
.repeat-animation.ng-leave.ng-leave-active, 
.repeat-animation.ng-enter {
    opacity: 0;
    -webkit-transition: opacity 300ms linear;
    -moz-transition: opacity 300ms linear;
    transition: opacity 300ms linear;
}

/* Cickable cursor */
.clickable {
    cursor: pointer;
}

/* Label sizes */
.lb-sm {
  font-size: 12px;
}

.lb-md {
  font-size: 16px;
}

.lb-lg {
  font-size: 20px;
}

.btn-vert-block + .btn-vert-block {
	margin-top: 10px;    
}    

/* Add 10px margin to bottom of col divs */
[class*=col-] {
    margin-bottom: 10px;
}

/* Stop modal from removing background scroll*/
.modal-open {
    overflow-y: scroll;
}


  .textBoxFalse {
    background-color: red !important;
    color: white !important;
  }

  .textBoxTrue {
      background-color: #33aa33 !important;
      color: white !important;
  }

  .btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
}

.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    border-radius: 25px;
    font-size: 20px;
    line-height: 1.33;
}

.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
}

.rotate {
    transform:rotate(90deg);
    -ms-transform:rotate(90deg);
    -webkit-transform:rotate(90deg);
}

.chevron-right {
    -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
  }

  .btn-purple{ background-color: purple; color: white; border:1px solid purple;}